import { useSelector } from "react-redux";
import { Box } from "../../components/StyledComponent";
import Showcase from "./Showcase/Showcase";
import Profile from "./Showcase/Profile";

export default function Home() {
  return (
    <Box className="text-white w-full flex flex-col justify-center items-center">
      <Profile />
    </Box>
  );
}
