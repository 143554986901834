import {
    Box,
    // Button,
    // Heading,
    // Image,
  } from "../../../components/StyledComponent";
  import { useEffect, useRef, useState } from "react";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { RoutePattern } from "../../../routes/RoutePattern";
  import { SocialIcons } from "../../../components";
  import BG_IMAGE from "../../../assets/images/bg-image.jpeg";
  import MYPIC from "../../../assets/images/my-pic.png";
  // import { isMobile } from "../../../constants";

  import CHENAYDER from "../../../assets/images/projects/chenayder.png";
  import DRDEVU from "../../../assets/images/projects/dr.developer.png";
  import FLAUJAE from "../../../assets/images/projects/flaujae.png";
  import LILDURK from "../../../assets/images/projects/lildurk.png";
  import RJAE from "../../../assets/images/projects/rjae.png";
  import SNOT from "../../../assets/images/projects/snot.png";

  const data = [
    {
        name: "Flau'Jae",
        img: FLAUJAE,
        link: "https://theflaujaejohnson.com/",
    },
    {
        name: "Dr. Developer's U",
        img: DRDEVU,
        link: "https://drdevu.com",
    },
    {
        name: "Lil Durk Official",
        img: LILDURK,
        link: "https://www.lildurkofficial.com/",
    },
    {
        name: "RJae",
        img: RJAE,
        link: "https://rjaeofficial.com",
    },
    {
        name: "Chenayder",
        img: CHENAYDER,
        link: "https://www.chenayder.com/",
    },
    {
        name: "$NOT",
        img: SNOT,
        link: "https://snot.xyz",
    },
  ]
  
  const Profile = () => {
    return (
      <Box
        id={RoutePattern.HOME}
        className="relative flex flex-col justify-center items-center h-[90vh] md:h-screen w-full overflow-hidden touch-none"
      >
        <img src={BG_IMAGE} className="fixed opacity-30 w-full h-full object-cover object-left lg:object-center" />
        <Box className="z-10 flex flex-col justify-center items-center h-full w-full bg-cover backdrop-blur-sm">
            <div className="flex h-28 lg:h-36 w-10/12 lg:w-1/3 bg-black rounded-t-lg justify-start items-center pt-8 lg:pt-16 shadow-md shadow-white">
                <div className="bg-white w-fit h-fit m-2 lg:m-5 rounded-md z-10 shadow-md border-4 border-[#f0f0f0]">
                    <img src={MYPIC} className="w-24 lg:w-40" />
                </div>
                <div className="flex flex-col">
                    <h1 className="text-lg lg:text-xl font-semibold">Chris Lomuntad</h1>
                    <span>Palawan</span>
                </div>
            </div>
            <div className="flex justify-end items-end bg-slate-100 h-8 lg:h-14 w-10/12 lg:w-1/3 pr-2 lg:pr-5 shadow-md shadow-white">
                <SocialIcons />
            </div>
            <div className="bg-white h-fit w-10/12 lg:w-1/3 rounded-b-lg shadow-md shadow-white">
                <div className="px-2 lg:px-5 mb-2">
                    <h1 className="text-xl lg:text-2xl text-black mb-2">About</h1>
                    <div className="flex flex-col bg-slate-100 h-fit w-full px-5 py-2 text-black rounded-sm">
                        <span>Web Developer</span>
                        <span>Lives in Palawan</span>
                    </div>
                </div>
                <div className="px-2 lg:px-5 pb-2 lg:pb-5">
                    <h1 className="text-xl lg:text-2xl text-black">Recent Work</h1>
                    <div className="flex flex-wrap">
                        {data.map((item, idx) =>
                            <div
                                className="p-1 w-1/2 cursor-pointer"
                                onClick={() => {
                                    window.open(item.link);
                                }}
                            >
                                <img src={item.img} className="border border-black rounded-sm hover:scale-125 transition-all ease-in-out duration-500" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Box>
      </Box>
    );
  };
  
  export default Profile;
  