import {
  faApple,
  faFacebookF,
  faInstagram,
  faSpotify,
  faXTwitter,
  faYoutube,
  faLinkedinIn,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";

import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faMusic } from "@fortawesome/free-solid-svg-icons";

interface SocialIconsTypes {
  icon?: any;
  link: string;
  img?: string;
  isPandora?: boolean;
}

export const data: SocialIconsTypes[] = [
  {
    icon: faEnvelope,
    link: "mailto:admin@iknowitworks.org",
  },
  {
    icon: faGithub,
    link: "https://github.com/mmixx",
  },
  {
    icon: faFacebookF,
    link: "https://www.facebook.com/mmixx",
  },
  {
    icon: faXTwitter,
    link: "https://twitter.com/iKnowITWorks",
  },
  {
    icon: faLinkedinIn,
    link: "https://www.linkedin.com/in/iknowitworks/",
  },
];
